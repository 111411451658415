import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Lottie, useLottie } from 'react-lottie-hook';
import PropTypes from 'prop-types';
import gsap from 'gsap';
import debounce from 'lodash/debounce';

// eslint-disable-next-line import/no-cycle
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { FreezedEmitter } from 'features/common/services/emitter.service';
import { AnimationMap } from 'features/football/constants';
import { FullRectangle } from './common/FullRectangle';
import goal from './data/goal.json';

export function GoalAnimation(props) {
    const { team } = props;
    const [translate] = useTranslation();
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam, awayTeam } = store;
    const { statistics } = store.translationData;
    const { goals } = statistics;
    const goalRef = useRef(null);
    const scoreRef = useRef(null);
    const homeRef = useRef(null);
    const awayRef = useRef(null);

    function onAnimationComplete() {
        FreezedEmitter.emit('animationCompleted');
    }

    const debouncedAnimationComplete = debounce(onAnimationComplete, 100);

    const goalTimeLine = useRef(
        gsap.timeline({
            repeat: -1,
            repeatDelay: 0.1,
            yoyo: false,
            onRepeat: debouncedAnimationComplete,
        }),
    );
    const teamRef = team === homeTeam ? homeRef : awayRef;
    let homeTeamScore = goals[homeTeam].length;
    let awayTeamScore = goals[awayTeam].length;

    if (team === homeTeam) {
        homeTeamScore -= 1;
        // Bug: team is undefined sometimes
    } else if (team === awayTeam) {
        awayTeamScore -= 1;
    }

    const initialScore = [homeTeamScore, awayTeamScore];
    const [lottieRef] = useLottie({
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            progressiveLoad: false,
        },
        animationData: goal,
    });

    useEffect(() => {
        let scoreToAdd;

        if (team === homeTeam) {
            // eslint-disable-next-line prefer-destructuring
            scoreToAdd = initialScore[0];
            // Bug: team is undefined sometimes
        } else if (team === awayTeam) {
            // eslint-disable-next-line prefer-destructuring
            scoreToAdd = initialScore[1];
        }

        goalTimeLine.current
            .fromTo(
                goalRef.current,
                {
                    opacity: 0,
                    scale: 0,
                },
                {
                    opacity: 1,
                    scale: 1,
                    duration: 0.5,
                },
            )
            .to(goalRef.current, {
                opacity: 0,
                scale: 0,
                delay: 1,
                duration: 0.5,
                display: 'none',
            })
            .fromTo(
                scoreRef.current,
                {
                    opacity: 0,
                    scale: 0,
                    display: 'none',
                },
                {
                    opacity: 1,
                    scale: 1,
                    duration: 0.5,
                    display: 'flex',
                },
            )
            .fromTo(teamRef.current, { scale: 1 }, { scale: 0.5, opacity: 0 })
            .set(teamRef.current, { innerText: (scoreToAdd += 1) })
            .to(teamRef.current, { scale: 1, opacity: 1 })
            .to(scoreRef.current, { delay: 0.5, scale: 0, opacity: 0 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => goalTimeLine.current.kill();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [goalRef, scoreRef, teamRef]);
    return (
        <>
            <FullRectangle />
            <div className="goal-animation">
                <div ref={goalRef}>
                    <div className="goal-animation__icon">
                        <Lottie lottieRef={lottieRef} width="100%" height="100%" />
                    </div>
                    <div className="goal-animation__team-container">
                        <p className="goal-animation__team-text">{translate('action.goal.animation')}</p>
                        <p className="goal-animation__team-team">
                            {team === homeTeam && translate('team1')}
                            {team === awayTeam && translate('team2')}
                        </p>
                    </div>
                </div>
                <div ref={scoreRef} className="goal-animation__score" style={{ display: 'none' }}>
                    <p ref={homeRef} style={{ opacity: team === 1 ? 1 : 0.5 }}>
                        {initialScore[0]}
                    </p>
                    <p>:</p>
                    <p ref={awayRef} style={{ opacity: team === 1 ? 0.5 : 1 }}>
                        {initialScore[1]}
                    </p>
                </div>
            </div>
        </>
    );
}

GoalAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

GoalAnimation.ANIMATION_NAME = AnimationMap.Goal;
