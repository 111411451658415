import React from 'react';
import cn from 'classnames';

import { Star } from '../Icons/loader/Star';

export const Mostbet2Loader = ({ className }) => (
    <div className={cn('loader', className)}>
        <div className="loader__spinner">
            <div className="loader__spinner-dot" />
        </div>
        <div className="loader__icon">
            <Star />
        </div>
    </div>
);
