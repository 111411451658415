import { TARGETS } from 'features/common/constants';

export const getEnvironmentTargets = () => {
    const targetName = process.env.REACT_APP_TARGET ? TARGETS[process.env.REACT_APP_TARGET] : TARGETS.MOSTBET;

    return {
        targetName,
        isBetandreas: targetName === TARGETS.BETANDREAS,
        isMostbet: targetName === TARGETS.MOSTBET,
        isMostbetV2: targetName === TARGETS['MOSTBET-2'],
        isVivi: targetName === TARGETS.VIVI,
    };
};
