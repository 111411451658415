import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useTargetName } from 'hooks';

const getClass = (condition) =>
    condition ? 'statistic-section_badge_value__max' : 'statistic-section_badge_value__min';

export function StatisticBadge({ animationName, useStatisticMap }) {
    const statistic = useStatisticMap(animationName);

    const { classMap } = useTargetName((targets) => ({
        [targets.VIVI]: {
            class: 'vivi',
        },
    }));

    return statistic ? (
        <div className={cx('statistic-section', classMap?.class)}>
            <div className="statistic-section_badge">
                <span className="statistic-section_badge_label">{statistic.label}</span>
                <span className={cx(getClass(statistic.homeStat > statistic.awayStat))}>{statistic.homeStat}</span>
                <span className="statistic-section_badge_value_divider" />
                <span className={cx(getClass(statistic.awayStat > statistic.homeStat))}>{statistic.awayStat}</span>
            </div>
        </div>
    ) : null;
}

StatisticBadge.propTypes = {
    animationName: PropTypes.string.isRequired,
    useStatisticMap: PropTypes.func.isRequired,
};
