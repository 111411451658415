import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useFootballCirclesTL } from 'hooks';
import { addToRefs } from 'features/football/utils';

export function CornerCircles(props) {
    const { circles, team, isBottomCorner } = props;
    const circlesRefs = useFootballCirclesTL();
    const isHome = team === 1;

    return (
        <div className="corner-circle_cont">
            <div
                className={cn('corner-circle_outer', {
                    '_top-right': isHome && !isBottomCorner,
                    '_bottom-right': isHome && isBottomCorner,
                    '_top-left': !isHome && !isBottomCorner,
                    '_bottom-left': !isHome && isBottomCorner,
                })}
            >
                <span
                    className={cn(
                        'corner-circle_inner',
                        isHome ? 'corner-circle_inner--home' : 'corner-circle_inner--away',
                    )}
                />
            </div>
            <div
                className={cn('corner-circle_inner_cont', {
                    '_top-right': isHome && !isBottomCorner,
                    '_bottom-right': isHome && isBottomCorner,
                    '_top-left': !isHome && !isBottomCorner,
                    '_bottom-left': !isHome && isBottomCorner,
                })}
            >
                {Array.from({ length: circles }, (_, index) => (
                    <span ref={(el) => addToRefs(el, circlesRefs)} key={index} className="corner-circle_circles" />
                ))}
            </div>
        </div>
    );
}

CornerCircles.propTypes = {
    circles: PropTypes.number.isRequired,
    team: PropTypes.number.isRequired,
    isBottomCorner: PropTypes.bool.isRequired,
};
