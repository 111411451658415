/* eslint-disable max-len */
import React from 'react';

export function Star() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M15.9638 25.427L25.8462 31.2973L23.2398 20.2049L32 12.7499L20.4887 11.7775L16 1.33331L11.5113 11.8135L0 12.7859L8.72398 20.2409L6.08145 31.3333L15.9638 25.427Z"
                fill="#FAB601"
            />
        </svg>
    );
}
