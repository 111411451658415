import { animationFunctions } from 'features/basketball3x3/animations/animationFunctions';
import { statistics as statisticsInitStat } from 'features/basketball3x3/machines/statistics.context';
import { FreezedEmitter } from 'features/common/services/emitter.service';
import { EventFactory } from '../models';

const AvailableEvents = EventFactory();

export class EventProcessor {
    constructor(store, animator) {
        this.store = store;
        this.emitter = FreezedEmitter;
        this.availableTypes = AvailableEvents.reduce((result, processor) => {
            if (typeof processor.type !== 'number') {
                result.push(...processor.type);
                return result;
            }
            result.push(processor.type);
            return result;
        }, []);
        this.animationProcessors = AvailableEvents;
        this.playedEvents = [];
        this.allEvents = [];
        this.animator = animator;
        this.animationFunctions = animationFunctions;
    }

    // eslint-disable-next-line class-methods-use-this
    globalEventHooks() {
        return [this.setTimerHook, this.setPeriodHook];
    }

    // eslint-disable-next-line class-methods-use-this
    setPeriodHook(evtData, store) {
        const hasPeriod = Object.prototype.hasOwnProperty.call(evtData, 'period');
        if (hasPeriod && evtData.period) {
            // eslint-disable-next-line no-param-reassign
            store.translationData.statistics.currentPeriod = evtData.period;
        }
    }

    // eslint-disable-next-line class-methods-use-this
    setTimerHook(evtData, store) {
        if (
            Object.prototype.hasOwnProperty.call(evtData, 'time') &&
            !Object.prototype.hasOwnProperty.call(evtData, 'isDontShowTime') &&
            !store.translationData.statistics.isTimerPaused
        ) {
            // eslint-disable-next-line no-param-reassign
            store.translationData.statistics.timer = evtData.time;
        }
    }

    callEventHooks(event) {
        /* a: matchActions t: processed Event data , e: the processor */
        this.globalEventHooks().forEach((globalEvent) => {
            globalEvent(event, this.store);
        });
    }

    resetMatchCenter() {
        this.animator.reset();
        this.playedEvents = [];
        this.resetStatsAndLogs();
        const acceptedEvents = this.getAcceptedEvents();
        this.processEvents(acceptedEvents, true);
    }

    resetStatsAndLogs() {
        this.store.translationData.statistics = statisticsInitStat();
        this.store.translationData.log = [];
        this.store.translationData.lastAction = null;
    }

    // eslint-disable-next-line class-methods-use-this
    checkChunkForChangeOfState(events) {
        const lookForServerStateChanges = events.filter((evt) => evt.type === 1020).map((e) => e.i2);
        return lookForServerStateChanges.length > 0;
    }

    checkForNewEvents(events) {
        // const filteredNewEvents = EventProcessor.filterNewEvents(events);
        this.addNewEvents(events);
        const isServerStateChanged = this.checkChunkForChangeOfState(events);
        if (isServerStateChanged) {
            this.resetMatchCenter();
        } else {
            this.processEvents(events, false);
        }
    }

    addNewEvents(events) {
        const { allEvents } = this;

        const sortedAndFilteredEvents = events
            // eslint-disable-next-line no-prototype-builtins
            .filter((evt) => !evt.hasOwnProperty('isFakeEvent'))
            .sort((a, b) => a.id - b.id);
        allEvents.push(...sortedAndFilteredEvents);
    }

    getAcceptedEvents() {
        const { allEvents } = this;
        // this lines checks if the type 1020 has some event ids which should be not accepted for
        // further processing
        const acceptedEvents = allEvents.filter((evt) => evt.type === 1020).map((e) => e.i2);
        return allEvents.filter((evt) => !acceptedEvents.includes(evt.id));
    }

    doCalculations(processor, processEventData) {
        if (processor.calculate) {
            processor.calculate(this.store, processEventData);
        }
        if (processor.pushToLog) {
            processor.pushToLog(this.store.translationData.log, processEventData, this.store);
        }
    }

    checkDuplicate(processor, playedEvents, processedEventData) {
        if (!processor.isDuplicate) {
            return false;
        }

        const lastEvent = playedEvents[playedEvents.length - 1];

        if (!lastEvent) {
            return false;
        }

        const processedLastEventData = this.getProcessor(lastEvent).processEventData(lastEvent);
        return processor.isDuplicate(processedEventData, processedLastEventData);
    }

    processEvent(event, processAnimation = false) {
        const processorAndProcessedEventData = this.getProcessorAndProcessedEvent(event);
        if (processorAndProcessedEventData) {
            const [processor, processedEventData] = processorAndProcessedEventData;
            // eslint-disable-next-line no-inner-declarations
            const processAdditionalEvents = () => {
                processor.pushAdditionalEvents(processedEventData, this.store).forEach((additionalEvent) => {
                    this.processEvent(additionalEvent, processAnimation);
                });
            };
            this.callEventHooks(processedEventData, processor);
            this.doCalculations(processor, processedEventData);
            if (!processor.isWithoutAnimation) {
                const isDuplicate = this.checkDuplicate(processor, this.playedEvents, processedEventData);
                if (!isDuplicate) {
                    if (processAnimation) {
                        this.lastInitialEventToAnimate = processedEventData;
                    } else {
                        this.processEventAnimation(processedEventData);
                    }
                }
                this.playedEvents.push(processedEventData);
                processAdditionalEvents();
            } else {
                processAdditionalEvents();
            }
        }
    }

    processEvents(events, processAnimation = false) {
        const filteredEvents = events.filter((event) => this.availableTypes.includes(event.type));
        filteredEvents.forEach((event) => this.processEvent(event, processAnimation));

        if (processAnimation && this.lastInitialEventToAnimate) {
            this.processEventAnimation(this.lastInitialEventToAnimate);
        }
    }

    // eslint-disable-next-line no-unused-vars
    playAnimationForDefaultProcessor(processor, processedEventData) {
        // eslint-disable-next-line no-unused-vars
        const animationFunction = this.animationFunctions.find(
            (animation) => animation.ANIMATION_NAME === processor.animationName,
        );
        if (!animationFunction) {
            throw new Error(`Can't find animation factory for animation ${JSON.stringify(processor.animationName)}`);
        }
        this.animator.queue.push({
            eventData: processedEventData,
            componentName: animationFunction.ANIMATION_NAME,
        });
    }

    getProcessorAndProcessedEvent(event, preventProcessing = false) {
        const processor = this.getProcessor(event);
        if (!processor) {
            throw new Error(`Cannot find processor for event ${JSON.stringify(event)}`);
        }
        const processedEventData = processor.processEventData(event);

        if (!preventProcessing && processor.isPreventProcessing(processedEventData, this.store)) {
            return null;
        }

        if (processor.redirect) {
            const redirectedEvent = processor.redirect(
                processedEventData,
                this.store.translationData.statistics,
                this.store,
            );
            if (Object.prototype.hasOwnProperty.call(redirectedEvent, 'type')) {
                const redirectedProcessor = this.getProcessor(redirectedEvent);
                return [redirectedProcessor, redirectedProcessor.processEventData(redirectedEvent)];
            }
        }

        return [processor, processedEventData];
    }

    getProcessor(event) {
        return this.animationProcessors.find((processor) =>
            typeof processor.type === 'number' ? processor.type === event.type : processor.type.includes(event.type),
        );
    }

    processEventAnimation(event) {
        const processorAndProcessedEventData = this.getProcessorAndProcessedEvent(event, true);
        if (processorAndProcessedEventData) {
            const [processor, processedEventData] = processorAndProcessedEventData;
            this.playAnimationForDefaultProcessor(processor, processedEventData);
        }
    }

    // eslint-disable-next-line class-methods-use-this
    getInitialFakeEvents() {
        return [];
    }

    processInitialFakeEvents() {
        const initialFakeEvents = this.getInitialFakeEvents().map((fakeEvt) => ({
            ...fakeEvt,
            isFakeEvent: true,
        }));
        this.processEvents(initialFakeEvents, false);
    }

    processInitialEvents(events) {
        this.allEvents = [];
        this.addNewEvents(events);
        this.processInitialFakeEvents();
        const acceptedEvents = this.getAcceptedEvents();
        this.processEvents(acceptedEvents, true);
    }
}
