import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { outPositions } from 'features/football/constants';

export function OutBallPoint(props) {
    const { team, position } = props;
    const isHome = team === 1;

    const placerCls = cn('out-placement', {
        '_top-right': isHome,
        '_top-left': !isHome,
    });

    const placerClsWithPosition = cn('out-placement', {
        '_top-right': position === outPositions.topRight,
        '_top-left': position === outPositions.topLeft,
        '_bottom-right': position === outPositions.bottomRight,
        '_bottom-left': position === outPositions.bottomLeft,
    });

    let placerClsClasses = placerCls;

    if (position && position !== outPositions.unknown) {
        placerClsClasses = placerClsWithPosition;
    } else if (position === outPositions.unknown) {
        placerClsClasses = '';
    }

    return (
        <div className="out-container">
            <div className={placerClsClasses}>
                <div className="out-outer-dot">
                    <span className={cn('out-inner-dot', isHome ? 'out-inner-dot--home' : 'out-inner-dot--away')} />
                </div>
            </div>
        </div>
    );
}

OutBallPoint.propTypes = {
    team: PropTypes.number.isRequired,
    position: PropTypes.string.isRequired,
};
