import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { Cross } from 'ui/atoms/Icons/Cross';
import { AnimationMap } from 'features/football/constants';
import { usePenaltyGoalStatistic } from 'features/football/hooks';
import { FieldIcon } from './common/FieldIcon';
import { FieldStatus } from './common/FieldStatus';
import { FieldText } from './common/FieldText';
import { HalfRectangle } from './common/HalfRectangle';

export function PenaltyMissAnimation(props) {
    const { team } = props;
    const [translate] = useTranslation();

    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    const { label: subtext, awayStat, homeStat } = usePenaltyGoalStatistic();

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} />
            <FieldText
                title={translate('action.penaltyMiss.animation')}
                subText={subtext}
                team={team}
                homeStat={homeStat}
                awayStat={awayStat}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
            />
            <FieldIcon animationName="penaltyMiss" team={team} />
            <FieldStatus team={team}>
                <Cross className="penalty-icon" />
            </FieldStatus>
        </div>
    );
}

PenaltyMissAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

PenaltyMissAnimation.ANIMATION_NAME = AnimationMap.PenaltyMiss;
