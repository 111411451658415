import React from 'react';
import { Lottie, useLottie } from 'react-lottie-hook';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { animationDataMap } from 'features/volleyball/animations/data';

export function FieldIcon(props) {
    const { animationName, team } = props;
    const isHome = team === 1;

    const [lottieRef] = useLottie({
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            progressiveLoad: false,
        },
        animationData: animationDataMap[animationName],
    });

    const transform = team === 1 ? 'scaleX(1)' : 'scaleX(-1)';
    const iconWrpCls = cx('icon-size', {
        'justify-end': isHome,
        'justify-start': !isHome,
    });

    return (
        <div className={iconWrpCls}>
            <div className="icon-size__content" style={{ transform }}>
                <Lottie lottieRef={lottieRef} width="100%" height="100%" />
            </div>
        </div>
    );
}

FieldIcon.propTypes = {
    animationName: PropTypes.string.isRequired,
    team: PropTypes.number.isRequired,
};
