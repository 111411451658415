import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useFootballCirclesTL } from 'hooks';
import { Cross } from 'ui/atoms/Icons/Cross';
import { addToRefs } from 'features/football/utils';

export function FreeKickMissCircles(props) {
    const { circles, team } = props;
    const isHome = team === 1;
    const circlesRefs = useFootballCirclesTL();

    return (
        <div className={cn('freekick-circle_cont _miss', isHome ? '_left' : '_right')}>
            <div className={cn('freekick-circle_outer', isHome ? '_left' : '_right')}>
                <span
                    className={cn(
                        'freeKick-circle_inner',
                        isHome ? 'freeKick-circle_inner--home' : 'freeKick-circle_inner--away',
                    )}
                />
            </div>
            <div className={cn('freeKick-circle_inner_cont', isHome ? '_left' : '_right')}>
                {Array.from({ length: circles }, (_, index) => (
                    <span ref={(el) => addToRefs(el, circlesRefs)} key={index} className="freeKick-circle_circles" />
                ))}
            </div>
            <div className={cn('freeKick-status', isHome ? '_left' : '_right')}>
                <Cross className="fill-current h-2 w-2 sm:h-4 sm:w-4" />
            </div>
        </div>
    );
}

FreeKickMissCircles.propTypes = {
    circles: PropTypes.number.isRequired,
    team: PropTypes.number.isRequired,
};
