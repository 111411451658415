import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useFootballCirclesTL } from 'hooks';

export function FreeKickCircles(props) {
    const { circles, team } = props;
    const isHome = team === 1;
    const circlesRefs = useFootballCirclesTL();

    function addToRefs(el) {
        if (el && !circlesRefs.current.includes(el)) {
            circlesRefs.current.push(el);
        }
    }

    return (
        <div className={cn('freekick-circle_cont', isHome ? '_left' : '_right')}>
            <div className={cn('freekick-circle_outer', isHome ? '_left' : '_right')}>
                <span
                    className={cn(
                        'freeKick-circle_inner',
                        isHome ? 'freeKick-circle_inner--home' : 'freeKick-circle_inner--away',
                    )}
                />
            </div>
            <div className={cn('freeKick-circle_inner_cont', isHome ? '_left' : '_right')}>
                {Array.from({ length: circles }, (_, index) => (
                    <span ref={(el) => addToRefs(el, circlesRefs)} key={index} className="freeKick-circle_circles" />
                ))}
            </div>
        </div>
    );
}

FreeKickCircles.propTypes = {
    circles: PropTypes.number.isRequired,
    team: PropTypes.number.isRequired,
};
