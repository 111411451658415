import React from 'react';

import { useTargetName } from 'hooks';
import { DefaultLoader } from './DefaultLoader';
import { Mostbet2Loader } from './Mostbet2Loader';
import { ViviLoader } from './ViviLoader';

export const Loader = ({ className }) => {
    const { conditions } = useTargetName();

    if (conditions.isVivi) {
        return <ViviLoader className={className} />;
    }

    if (conditions.isMostbetV2) {
        return <Mostbet2Loader className={className} />;
    }

    return <DefaultLoader className={className} />;
};
