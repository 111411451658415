import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const FieldStatus = React.forwardRef((props, ref) => {
    const { team, children } = props;
    const isHome = team === 1;

    return (
        <div className={cn('field-status', isHome ? '_left' : '_right')} ref={ref}>
            <div className="field-status__wrapper">{children}</div>
        </div>
    );
});

FieldStatus.displayName = 'FieldStatus';
FieldStatus.propTypes = {
    team: PropTypes.number.isRequired,
};
