import React, { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import i18n from 'i18n';

import { useCallbackTL, useNodeTimeline } from 'hooks';
import { CountdownAnimation } from 'ui/molecules';
import { AnimationMap } from 'features/beachVolleyball/constants';
// eslint-disable-next-line import/no-cycle
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { formattedTimer, getLocalTime } from 'features/common/utils';

export function MatchCountdownAnimation() {
    let timerId;
    const statusText = {
        matchWillBegin: i18n.t('base.toMatchStart'),
        matchNotStartYet: i18n.t('base.matchNotStartedYet'),
        matchBeginSoon: i18n.t('base.matchStartsSoon'),
    };
    const [status, setStatus] = useState(statusText.matchNotStartYet);
    const [showCountdown, setShowCountdown] = useState(false);
    const [countdownText, setCountdownText] = useState('');

    const { current } = useContext(RootMachineContext);
    const { translationData } = current.context.store;

    const matchStartDate = translationData.info
        ? getLocalTime(translationData.info.start)
        : new Date(translationData.lineMatchInfo.startTimestamp);

    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    useEffect(() => {
        const currentTime = new Date().getTime();
        const startTime = new Date(matchStartDate).getTime();

        function checkToShowCountdown(stTime, crTime) {
            return stTime - crTime > 3600 && stTime - crTime < 900000;
        }

        function startTimer() {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            timerId = setInterval(() => {
                const currTime = new Date().getTime();
                const srtTime = new Date(matchStartDate).getTime();
                const timer = Math.floor((srtTime - currTime) / 1000);

                if (checkToShowCountdown(srtTime, currTime)) {
                    const formattedTime = formattedTimer(timer);
                    setCountdownText(formattedTime);
                } else {
                    clearInterval(timerId);
                    setShowCountdown(false);
                    setStatus(statusText.matchBeginSoon);
                }
            }, 1000);
        }

        if (checkToShowCountdown(startTime, currentTime)) {
            const timer = Math.floor((startTime - currentTime) / 1000);
            const formattedTime = formattedTimer(timer);
            setCountdownText(formattedTime);
            setShowCountdown(true);
            setStatus(statusText.matchWillBegin);
            startTimer();
        } else if (startTime - currentTime < 3600) {
            setShowCountdown(false);
            setStatus(statusText.matchBeginSoon);
        } else if (startTime - currentTime > 900000) {
            setShowCountdown(false);
            setStatus(statusText.matchNotStartYet);
        }
        return () => {
            if (timerId) {
                clearInterval(timerId);
            }
        };
    }, []);

    const statusCls = cx('text-2xl sm:text-3xl', {
        'mb-auto': showCountdown,
    });

    const countdownAnimation = (
        <div className="h-full" ref={nodeRef}>
            <div className="full_rectangle">
                {showCountdown && <p className="text-3xl mt-10% sm:text-4xl">{countdownText}</p>}
                <p className={statusCls}>{status}</p>
            </div>
        </div>
    );

    return (
        <CountdownAnimation
            showCountdown={showCountdown}
            defaultComponent={countdownAnimation}
            status={status}
            countdownText={countdownText}
        />
    );
}

MatchCountdownAnimation.ANIMATION_NAME = AnimationMap.MatchCountdown;
