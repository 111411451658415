import { TARGETS } from 'features/common/constants';

/**
 * Функция для динамического импорта стилей, в зависимости от переменной окружения
 * @param styleImportsObj - объект с ассинхронными функциями для импорта стилей
 * @returns {Promise<*>}
 */
export const getPageStyles = async (styleImportsObj) => {
    const targetName = process.env.REACT_APP_TARGET ? TARGETS[process.env.REACT_APP_TARGET] : TARGETS.MOSTBET;
    try {
        return await styleImportsObj[targetName]();
    } catch (e) {
        throw Error(`No styles for page ${e}`);
    }
};
