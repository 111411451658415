import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export function Transformer(props) {
    const { children, zIndex, className } = props;
    return (
        <div className={cx('transformer', className)} style={{ zIndex }}>
            {children}
        </div>
    );
}

Transformer.propTypes = {
    zIndex: PropTypes.number,
    className: PropTypes.string,
};

Transformer.defaultProps = {
    zIndex: 0,
    className: '',
};
