import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallbackTL, useNodeTimeline } from 'hooks';
import { AnimationMap } from 'features/badminton/constants';
// eslint-disable-next-line import/no-cycle
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';

export function SetStartAnimation() {
    const [translate] = useTranslation();
    const { current } = useContext(RootMachineContext);
    const { sets } = current.context.store.translationData.statistics;

    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    return (
        <div className="h-full" ref={nodeRef}>
            <div className="full_rectangle">
                <p className="animation__field--text-action">{translate('time.setStart')}</p>
                <p className="badminton-text-primary-light animation__field--additional-text">
                    {translate('time.set', { period: sets.length })}
                </p>
            </div>
        </div>
    );
}

SetStartAnimation.ANIMATION_NAME = AnimationMap.SetStart;
