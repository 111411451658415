import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallbackTL, useNodeTimeline } from 'hooks';
import { AnimationMap } from 'features/badminton/constants';
// eslint-disable-next-line import/no-cycle
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { ze } from '../models/common';

export function SetEndAnimation() {
    const [translate] = useTranslation();
    const { current } = useContext(RootMachineContext);
    const { sets } = current.context.store.translationData.statistics;
    const { homeTeam, awayTeam } = current.context.store;
    const isMatchEnd = ze(sets, homeTeam, awayTeam);
    const finishedSet = isMatchEnd ? sets.length : sets.length - 1;

    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    return (
        <div className="h-full" ref={nodeRef}>
            <div className="full_rectangle">
                <p className="animation__field--text-action">{translate('time.setFinished')}</p>
                <p className="badminton-text-primary-light animation__field--additional-text">
                    {translate('time.set', {
                        period: finishedSet,
                    })}
                </p>
            </div>
        </div>
    );
}

SetEndAnimation.ANIMATION_NAME = AnimationMap.SetEnd;
