import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useFootballCirclesTL } from 'hooks';
import { addToRefs } from 'features/football/utils';

export function ShotOnGoalMissCircles(props) {
    const { circles, team } = props;
    const isHome = team === 1;
    const circlesRefs = useFootballCirclesTL();

    return (
        <div className={cn('shotOnGoalMiss-circle_cont', isHome ? '_left' : '_right')}>
            <div className={cn('shotOnGoalMiss-circle_outer', isHome ? '_left' : '_right')}>
                <span
                    className={cn(
                        'shotOnGoalMiss-circle_inner',
                        isHome ? 'shotOnGoalMiss-circle_inner--home' : 'shotOnGoalMiss-circle_inner--away',
                    )}
                />
                <div className={cn('shotOnGoalMiss-circle_inner_cont', isHome ? '_left' : '_right')}>
                    {Array.from({ length: circles }, (_, index) => (
                        <span
                            ref={(el) => addToRefs(el, circlesRefs)}
                            key={index}
                            className="shotOnGoalMiss-circle_circles"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

ShotOnGoalMissCircles.propTypes = {
    circles: PropTypes.number.isRequired,
    team: PropTypes.number.isRequired,
};
