import React from 'react';
import PropTypes from 'prop-types';

import { useNodeTLCallback } from 'hooks';

export function TitleCaption(props) {
    const { title, caption } = props;

    const nodeRef = useNodeTLCallback();

    return (
        <div ref={nodeRef}>
            <div className="full_rectangle" />
            <div className="playing_status">
                <div className="playing_status--title">
                    <p>{title}</p>
                </div>
                <div className="playing_status--subtext">
                    <p>{caption}</p>
                </div>
            </div>
        </div>
    );
}

TitleCaption.propTypes = {
    title: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
};
