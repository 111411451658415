import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useFootballCirclesTL } from 'hooks';
import { addToRefs } from 'features/football/utils';

function WithRefKickFromGoalCircles(props, ref) {
    const { team, circles } = props;
    const isHome = team === 1;
    const circlesRefs = useFootballCirclesTL();

    return (
        <div className={cn('goalKick', isHome ? '_left' : '_right')}>
            <div ref={ref} className={cn('goalKick_placer', isHome ? '_left' : '_right')}>
                <div className="goalKick_outer-circle">
                    <span
                        className={cn(
                            'goalKick_inner-circle',
                            isHome ? 'goalKick_inner-circle--home' : 'goalKick_inner-circle--away',
                        )}
                    />
                </div>
                <div className="goalKick_circle">
                    {Array.from({ length: circles }, (_, index) => (
                        <span
                            ref={(el) => addToRefs(el, circlesRefs)}
                            key={index}
                            className="goalKick_circle-circles"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export const KickFromGoalCircles = React.forwardRef(WithRefKickFromGoalCircles);

WithRefKickFromGoalCircles.propTypes = {
    team: PropTypes.number.isRequired,
    circles: PropTypes.number.isRequired,
};
