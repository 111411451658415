import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function WithRefFullRectangle(props, ref) {
    const { isAnimated } = props;

    const classes = cx('full_rectangle', {
        _isAnimated: !isAnimated,
    });
    return <div className={classes} ref={ref} />;
}

export const FullRectangle = React.forwardRef(WithRefFullRectangle);

WithRefFullRectangle.propTypes = {
    isAnimated: PropTypes.bool,
};

WithRefFullRectangle.defaultProps = {
    isAnimated: true,
};
